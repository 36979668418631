export default function useInfiniteScroll(isLoading, searchStore) {

  const fetchMore = ref(false);

  const isNearBottom = ()=> {
    const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
    const scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;

    let footer_container = document.querySelector('.footer-area-bg');

    // Force the footer to be visible and work with infinite scroll
    if (scrollTop > 1000) {
      if (footer_container != null) {
        document.querySelector('.footer-area-bg').style.position = 'sticky'
      }
    } else {
      if (footer_container != null) {
        document.querySelector('.footer-area-bg').style.position = 'static'
      }
    }
    let near_bottom = isElementInViewport(document.getElementById('near_bottom_detection')) // near_bottom_detection
    return near_bottom;
    //return (scrollTop + window.innerHeight) >= (scrollHeight - 500); // 500 is the distance from the bottom
  }
  
  const handleScroll = () => {
    fetchMore.value = false;
    if ((isNearBottom()) && (!isLoading.value) && (searchStore.getSearchPagination.has_more_results || searchStore.getCommunitySearchPagination.has_more_results)) {
      fetchMore.value = true;
    }
  };

  onMounted(() => {
    window.addEventListener('scroll', handleScroll);
  });

  onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
  });

  return {
    fetchMore
  };
}
