<template>
    <div ref="filters_div_ref">
      <!--components/Filters.vue-->
      <ul class="d-flex filter-btn">
          <li>
            <label ref="filters_show_all_ref"><input class="filter_show_all" type="checkbox" value="1" data-bs-toggle="tooltip" data-bs-placement="left" title="Show all tasks" @click="toggle_show_all_tasks"/></label>
          </li>
         <li 
            class="class_most_recent" 
            :class="{'filter-btn-active': 'most_recent' === selectedFIlter}"
            @click="$emit('get_filtered_data', 'Most recent')">
            Most recent
         </li> 
         <li 
            class="class_most_liked" 
            :class="{'filter-btn-active': 'most_liked' === selectedFIlter}"
            @click="$emit('get_filtered_data', 'Most liked')">
            Most popular
         </li> 
         <li 
            class="class_most_linked" 
            :class="{'filter-btn-active': 'most_linked' === selectedFIlter}"
            @click="$emit('get_filtered_data', 'Most linked')">
            Most linked
         </li> 
         <li v-if="community.toLowerCase() == 'true'"
            class="class_most_downloaded" 
            :class="{'filter-btn-active': 'most_download' === selectedFIlter}"
            @click="$emit('get_filtered_data', 'Most download')">
            Most download
         </li> 
         <!--
          The Favorite button is a bit different from the other buttons.
          The Favorite button is like a SQL where clause, whereas the 
          other buttons are like SQL order clause.  I put the Favorite button here 
          because we didn't have any other place for it.  One thought was to put it 
          under the "login user" icon along with the "My runbooks" links, but did not get 
          good concensus from the team, so I put it here for now.
         -->
         <li 
            class="class_most_favorited" 
            :class="{'filter-btn-active': 'most_favorited' === selectedFIlter}"
            @click="$emit('get_filtered_data', 'Most Favorited')">
            Favorited
         </li> 
         <li v-if="has_approve_reject_privilege_ref.valueOf()"
            class="class_pending_approval" 
            :class="{'filter-btn-active': 'pending_approval' === selectedFIlter}"
            @click="$emit('get_filtered_data', 'Pending Approval')">
            Pending
         </li> 

      </ul>
    </div>
</template>

<script setup>
const route = useRoute();  
const props = defineProps({
  items: {
    type: Array
  },
});
const emits = defineEmits(["community_toggle", "get_filtered_data"])
const query = computed(() => {
    let  query = ''
    if (route.query) {
        query = route.query
    }
    return query
})

const selectedFIlter = ref('most_recent')
watch(query, (newVal, oldVal) => {
  console.log(`ORDER BY:`, query.value.order_by)
  selectedFIlter.value = query.value.order_by
  if (route.name === 'index' && Object.keys(newVal).length === 0) {
    selectedFIlter.value = 'most_recent';
  }
});

const env = await getEnv();
let community = (env.community || '').trim();

const filters_div_ref = ref(null);
onMounted(() => {
  initializeTooltips(filters_div_ref.value);
});

onBeforeUnmount(() => {
  hideTooltips(filters_div_ref.value, true);
});

let show_all_tasks_state = useShowAllTasksState();

const toggle_show_all_tasks = (event) => {
  hideTooltip(event.target);
  if (event.target.checked) {
    show_all_tasks_state.value = 1;
  } else {
    show_all_tasks_state.value = 0;
  }
}

let space_dropdown_state = useSpaceDropdownState();
let has_approve_reject_privilege_ref = ref(false);
has_approve_reject_privilege_ref.value = hasPrivilege('workspace.approve_publish', space_dropdown_state.value);

watch(space_dropdown_state, (newVal, oldVal) => {
  has_approve_reject_privilege_ref.value = hasPrivilege('workspace.approve_publish', space_dropdown_state.value);
});

</script>

<style scoped>
    .filter-btn{
      margin-left: 0;
      padding-left: 0;
    }
    .filter-btn li{

      font-size: 11px;
      padding: 10px;
      /* background-color: rgb(230, 223, 223); */
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000;
      height: 30px;
      font-weight: 400;
    }

    .filter-btn li:last-child {
      border-right: 1px solid #ccc;
    }
    
    .filter-btn li:hover, .filter-btn-active {
      cursor: pointer;
      background-color: #f3f3f3 !important;;
    }

    .class_most_recent,
    .class_most_liked,
    .class_most_linked,
    .class_most_downloaded,
    .class_most_favorited,
    .class_pending_approval {
      border-left: 1px solid #ccc !important;
      border-top: 1px solid #ccc !important;
      border-bottom: 1px solid #ccc !important;
    }
    .class_most_downloaded {
      border-right: 1px solid #ccc !important;
    }
</style>
